.loading-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-top: 10em;
    margin-left: -.5em;
    /* background-color: #000; */
}

.loader {
    display: flex;
    z-index: 1000;
}
  
.l,.l:nth-child(9) {
    margin: 0.25em;
    border-radius: 5em;
    width: .5em;
    background-color: #0070ff;
    height: 3em;
    box-shadow: 1px 1px 4px black;
    animation: load_5186 cubic-bezier(.41,.44,.72,.69) 2s infinite;
}
  
.l:nth-child(2), 
.l:nth-child(8) {
    background-color: #1f8ad5;
    animation-delay: .25s;
}
  
.l:nth-child(3), 
.l:nth-child(7) {
    background-color: #43a9a3;
    animation-delay: .5s;
}
  
.l:nth-child(4), 
.l:nth-child(6) {
    background-color: #62c479;
    animation-delay: .75s;
}
  
.l:nth-child(5) {
    background-color: #80de4e;
    animation-delay: 1s;
}
  
@keyframes load_5186 {
    0% {
      transform: scaleY(1.1);
    }
  
    100% {
      transform: scaleY(-1.1);
    }
}

@media (max-width: 768px) {
    .loading-view {
        padding-top: 10em;
    }
}