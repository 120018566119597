.track-view-container {
    width: 90%;
    border-radius: 19px;
    display: flex;
    padding: 1em;
    /* background-color: #2e2e2e; */
    margin-bottom: 6em;
}

.track-view {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.track-view-body-rest {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.track-view-body {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.track-view-header-text {
    color: #ccccccaa;
    font-size: 2.5rem;
    font-weight: bold;
}

.track-view-score {
    width: 80%;
    height: 80%;
    padding: .1em;
    margin-top: -1em;
    z-index: 2;
}

.track-view-score-text {
    color: #fff;
    /* font-size: 3rem; */
    font-weight: bold;
}

.track-view-body-number-view {
    display: flex;
    flex-direction: row;
    gap: 1em;
    width: 100%;
    margin-top: -1.8em;
}

.track-view-body-number-of-item {
    display: flex;
    align-self: flex-end;
    align-items: center;
    background-color: #333;
    padding: 0.1em 0.9em;
    border-radius: 19px;
}

.track-view-body-number-of-item:hover {
    transform: scale(1.1);
}

.track-view-body-number-of-text {
    color: #ccccccdd;
    font-size: 1rem;
    font-weight: 700;
    padding-left: 0.4em;
    padding-right: 0.2em;
}

.track-view-body-number-of-tweets-icon {
    width: 1.4em;
    height: 1.3em;
}

.track-view-body-number-of-icon {
    width: 1.3em;
    height: 1.3em;
}

.track-view-body-content {
    text-align: left;
    background-color: #333;
    padding: 0em 1em;
    border-radius: 19px;
    margin-top: 1em;
}

.track-view-body-content:hover {
    transform: scale(1.02);
}

.track-view-body-content-text {
    color: #ccccccff;
    font-size: 1.4rem;
    font-weight: 500;
    margin-top: .5em;
}

.track-view-body-key-words {
    display: flex;
    flex-direction: column;
    gap: 0em;
    align-items: flex-start;
}

.track-view-body-key-words-title {
    color: #ccccccaa;
    font-size: 1.5rem;
    font-weight: 700;
}

.track-view-body-key-words-text {
    color: #ccccccee;
    font-size: 1.5rem;
    font-weight: 600;
    /* margin-top: -0.5em; */
    text-transform: capitalize;
}

.track-view-question {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1em;
    width: 100%;

}

.track-view-question-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.track-view-question-input {
    width: 100%;
    height: 2.8em;
    border-radius: 19px;
    border: none;
    background-color: #333;
    color: #ccccccdd;
    font-size: 1.3rem;
    font-weight: 600;
    padding-left: 1em;
}

.track-view-question-input:focus {
    outline: none;
    border: 1px solid #ccccccdd;
}

.track-view-question-button {
    width: 5em;
    height: 2.8em;
    border-radius: 19px;
    border: none;
    background-color: #333;
    color: #ccccccdd;
    font-size: 1.3rem;
    font-weight: 600;
    margin-left: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.track-view-question-button:hover {
    border: 1px solid #ccccccdd;
}

.track-view-question-answer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1em;
    width: 100%;
    background-color: #333;
    /* padding: 1em; */
    border-radius: 19px;

}

.track-view-question-answer-text {
    color: #ccccccaa;
    font-size: 1.3rem;
    font-weight: 700;
    text-align: left;
    padding: 0em 1em 0em 1em;
    margin: 0.6em 0em 0.6em 0em;
}

.spinner {
    border: 2px solid #f3f3f3;
    border-radius: 50%;
    border-top: 2px solid #333;
    width: 16px;
    height: 16px;
    animation: spin 1s linear infinite;
    opacity: 0.5;
}
  
@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

@media (max-width: 900px) {
    .track-view-body {
        flex-direction: column;
        align-items: center;
        z-index: 5;
    }

    .track-view-score {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -2em;
        padding: 0;
        width: 100%;
        height: 100%;
    }

    .track-view-body-rest {
        margin-top: -2.5em;
    }

    .track-view-body-number-of-tweets {
        align-self: flex-end;
    }

    .track-view-header-text {
        font-size: 2.5rem;
    }

    .track-view-body-content-text {
        font-size: 1.3rem;
    }

    .track-view-body-number-of-tweets-image {
        width: 1.5em;
        height: 1.5em;
    }
    
    .track-view-body-key-words-title {
        font-size: 1.8rem;
    }
    
    .track-view-body-key-words-text {
        padding-left: .3em;
        font-size: 1.5rem;
    }

    .track-view-body-key-words {
        width: 100%;
    }

    .track-view-body-key-words-text {
        margin-left: -.4em;
        text-align: left;
    }
}