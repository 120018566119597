.intro-message-container {
    margin-top: 2.3em;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 55vw;
    gap: 0em;
}

.intro-message {
    /* padding: 1em; */
    display: flex;
    flex-direction: column;
}

.intro-message-title {
    color: #ccc;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    padding-bottom: 2em;
}

.intro-message-text {
    color: #ccc;
    font-size: 1.5rem;
    margin-top: -1em;
    font-weight: 600;
    text-align: center;
    /* max-width: 55vw; */
}

@media (max-width: 768px) {
    .intro-message-container {
        margin-top: 0em;
        width: 90vw;
    }

    .intro-message {
        padding: 1em;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .intro-message-title {
        font-size: 1.45rem;
        padding-bottom: 0em;
    }

    .intro-message-text {
        font-size: 1.25rem;
        max-width: 80vw;
    }
}