.input {
    min-height: 50px;
    max-width: 350px;
    color: #eee;
    font-size: 23px;
    width: 13rem;
    padding: .2em 1rem;
    border: 1px solid rgba(0, 112, 255, 1);
    border-radius: 6px 0 0 6px;
    background-color: transparent;
}

.input:focus, .input:focus-visible {
    border-color: rgb(0, 81, 255);
    outline: none;
}

.loading {
    opacity: 0.8;
}

.loading:focus-visible, .loading:focus {
    border-color: rgb(0, 30, 94);
    outline: none;
}

@media (max-width: 768px) {
    .input {
        /* width: 80%; */
        padding: .2em .7rem;
    }
}