.inputfield-container {
    /* margin-top: 5%; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 5;
}

@media screen and (max-width: 768px) {
    .inputfield-container {
        /* flex-direction: row; */
        transform: scale(0.9);
    }
}