.App {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  min-height: 100vh;
  text-align: center;
  background-color: #2c2c2c;
  
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 10;
}

.modal-open {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 10;
}

.App-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: .5%;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

.sentiment-scroll-view {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
  overflow: hidden;
  overflow-x: hidden;
}

.sentiment-divider {
  width: 90%;
  height: 1px;
  background-color: #eeeeeeff;
  opacity: 0.2;
}

@media screen and (max-width: 768px) {
  body {
    overflow: hidden;
    overflow-x: hidden;

  }
  .App {
    /* width: 95%; */
    overflow: hidden;
    overflow-x: hidden;
  }
  .App-main {
    margin-top: 0;
    width: 100%;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    overflow-x: hidden;
  }
}
