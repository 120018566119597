.footer-container {
    width: 100%;
    position: absolute;
    bottom: .01em;
    z-index: 3;
    margin-bottom: -.8em;
}

.footer-text {
    color: #eeeeeeff;
    font-size: 1.4rem;
    font-weight: semi-bold;
    opacity: .6;
}

.footer-img {
    opacity: .7;
}

.twitter-icon {
    width: 1.5em;
    height: 1.3em;
    margin-right: 0.5em;
}

.reddit-icon {
    width: 1.5em;
    height: 1.5em;
    margin-right: 0.5em;
}

.news-icon {
    width: 1.5em;
    height: 1.5em;
    margin-right: 0.5em;
}

.footer-logos {
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: center;
    justify-content: center;
    margin-bottom: -.8em;
}

.footer-img:hover {
    opacity: .9;
    transform: matrix3d(1.1, 0, 0, 0, 0, 1.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
}

.footer-link {
    /* padding: 0.5em; */
    background-color: transparent;
    border: none;
    font-size: 1.3rem;  
    color: #eeeeeeff;
}

.footer-link:hover {
    color: #bbbbbbf0;
    /* text-decoration: underline; */
}

@media (max-width: 768px) {
    .footer-container {
        bottom: .2em;
    }
    
    .footer-text {
        font-size: 1.1rem;
    }
    
    .twitter-icon {
        width: 1.5em;
        height: 1.3em;
        margin-right: 0.2em;
    }
    
    .reddit-icon {
        width: 1.5em;
        height: 1.5em;
        margin-right: 0.2em;
    }

    .news-icon {
        width: 1.6em;
        height: 1.6em;
        margin-right: 0.2em;
    }
    
    .footer-logos {
        display: flex;
        flex-direction: row;
        gap: 1em;
        align-items: center;
        justify-content: center;
        /* padding-bottom: 0.8em; */
    }
    
    .footer-img:hover {
        opacity: .9;
        transform: matrix3d(1.1, 0, 0, 0, 0, 1.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
}