.Modal {
    position: fixed;
    top: -10%;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

  
.card {
    overflow: hidden;
    position: relative;
    text-align: left;
    border-radius: 0.5rem;
    width: 70%;
    /* max-width: 290px; */
    box-shadow: rgba(0, 0, 0, 0.6) 0px 4px 8px, rgba(0, 0, 0, 0.6) 0px 14px 26px -1px, rgba(0, 0, 0, 0.5) 0px -5px 0px inset;
    background-color: #333;
    padding: 1rem;

}

.dismiss {
    position: absolute;
    right: 10px;
    top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .9rem 1rem;
    background-color: #ccc;
    color: #555;
    border: 2px solid #ccc;
    font-size: 1rem;
    font-weight: 300;
    width: 30px;
    height: 30px;
    border-radius: 7px;
    transition: 0.3s ease;
}

.dismiss:hover {
    background-color: #f46464;
    border: 2px solid #f46464;
    color: #ccc;
}

.header {
    padding: 1.25rem 1rem 1rem 1rem;
}

.bottom-content {
    margin-top: 2.5rem;
}

.content {
    text-align: center;
}

.title {
    color: #0070ff;
    font-size: 2rem;
    font-weight: 700;
    line-height: 3.5rem;
}

.form-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    margin-bottom: 1rem;
}

.form-group input,
.form-group textarea {
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    padding: 0.5rem;
    border: 1px solid #aaa;
    width: 100%;
    border-radius: 0.375rem;
    color: #ddd;
    background-color: transparent;
   
}

.submit {
    display: inline-flex;
    margin-top: 1rem;
    padding: .5rem 1rem;
    color: #ccc;
    background-color: #0061e1;
    border: none;
    border-radius: 0.375rem;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    justify-content: center;
    width: 100%;
    border-radius: 0.375rem;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px, rgba(0, 0, 0, 0.2) 0px 7px 13px -3px, rgba(0, 0, 0, 0.1) 0px -3px 0px inset;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.card p {
    font-size: 1.2rem;
    color: #ccc;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    max-width: 55em;
    text-align: left;
    margin-top: 1.5rem;
}

.submit:hover {
    background-color: #7aa961;
}

textarea {
    height: 100px;
    resize: none;
}

/* Add spinner styles */
.spinner {
    border: 2px solid #f3f3f3;
    border-radius: 50%;
    border-top: 2px solid #333;
    width: 16px;
    height: 16px;
    animation: spin 1s linear infinite;
}
  
@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}
  
/* Add success animation styles */
.success-animation {
    animation: fadeOut .5s linear forwards;
}
  
@keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
}

@media (max-width: 768px) {
    
}

@media (max-width: 1024px) {
    .Modal {
        top: -5%;
    }

    .card {
        padding: 0.3rem;
        width: 90%;
    }

    .card p {
        font-size: 1.1rem;
        margin-top: 1.1rem;
    }

    .bottom-content {
        margin-top: .5rem;
    }

    .form-group {
        flex-wrap: nowrap;
    }

    .form-group input,
    .form-group textarea {
        font-size: 1rem;
        width: 98%;
        flex-wrap: nowrap;
    }
}

