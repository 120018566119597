.track-button {
    min-height: 50px;
    padding: .96em 2em;
    border: none;
    border-radius: 0 6px 6px 0;
    background-color: rgba(0, 112, 255, 1);
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color .3s ease-in-out;
}

.track-button:hover {
    background-color:rgb(0, 81, 255);
}

@media (max-width: 768px) {
    .track-button {
        /* width: 100%; */
        /* margin-left: -25px; */
    }
}