/* The switch - the box around the slider */
.switch-container {
    display: flex;
    align-self: center;
    justify-content: center;
    margin-bottom: 1em;
}

.switch-text {
    font-size: 1.5em;
    font-weight: bold;
    padding: .5em 1em;
}

.switch-text-all {
    font-size: 1.5em;
    font-weight: bold;
    margin-left: .5em;
    padding: .5em 1em;
}

.switch {
    font-size: 1em;
    position: relative;
    display: inline-block;
    width: 1.2em;
    height: 3.3em;
    margin-top: 1.4em;
}
  
  /* Hide default HTML checkbox */
.switch .chk {
    opacity: 0;
    width: 0;
    height: 0;
}
  
  /* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #aaa;
    transition: .4s;
    border-radius: 5px;
}
  
.slider:before {
    position: absolute;
    content: "";
    height: .5em;
    width: 2.4em;
    border-radius: 5px;
    left: -0.6em;
    top: 0.2em;
    background-color: white;
    box-shadow: 0 6px 7px rgba(0,0,0,0.3);
    transition: .4s;
}
  
.slider:before, .slider:after {
    content: "";
    display: block;
}
  
.slider:after {
    background: linear-gradient(transparent 50%, rgba(255, 255, 255, 0.15) 0) 0 50% / 50% 100%,
          repeating-linear-gradient(90deg,rgb(255, 255, 255) 0,rgb(255, 255, 255),rgb(255, 255, 255) 20%,rgb(255, 255, 255) 20%,rgb(255, 255, 255) 40%) 0 50% / 50% 100%,
          radial-gradient(circle at 50% 50%,rgb(255, 255, 255) 25%, transparent 26%);
    background-repeat: no-repeat;
    border: 0.25em solid transparent;
    border-left: 0.4em solid #ffffff;
    border-right: 0 solid transparent;
    transition: border-left-color 0.1s 0.3s ease-out, transform 0.3s ease-out;
    transform: translateX(-22.5%) rotate(90deg);
    transform-origin: 25% 50%;
    position: relative;
    top: 0.5em;
    left: 0.55em;
    width: 2em;
    height: 1em;
    box-sizing: border-box;
}
  
.chk:checked + .slider {
    background-color: rgba(139,246,92,255);
}
  
.chk:focus + .slider {
    box-shadow: 0 0 1px rgba(139,246,92,255);
}
  
.chk:checked + .slider:before {
    transform: translateY(2.3em);
}
  
.chk:checked + .slider:after {
    transform: rotateZ(90deg) rotateY(180deg) translateY(0.45em) translateX(-1.4em);
}

.selected {
    color: rgba(139,246,92,255);
    opacity: 1;
}

.switch-text:not(.selected) {
    color: #ccc;
    opacity: 0.5;
}

.switch-text-all:not(.selected) {
    color: #ccc;
    opacity: 0.5;
}

@media (max-width: 768px) {
    .switch-container {
        margin-bottom: 0;
    }

    .switch-text {
        font-size: 1.3em;
        padding: .2em .8em;
    }

    .switch-text-all {
        font-size: 1.3em;
        margin-left: .5em;
        padding: .2em .8em;
    }

    .switch {
        font-size: 1em;
        width: .9em;
        height: 2.8em;
        margin-top: 1.2em;
    }

    .slider {
        height: 2.5em;
    }

    .slider:before {
        height: .4em;
        width: 2em;
        left: -0.5em;
        top: 0.1em;
    }

    .slider:after {
        top: 0.4em;
        left: 0.45em;
        width: 1.5em;
        height: 0.8em;
    }

    .chk:checked + .slider:before {
        transform: translateY(1.8em);
    }

    .chk:checked + .slider:after {
        transform: rotateZ(90deg) rotateY(180deg) translateY(0.35em) translateX(-1.2em);
    }
}