.track-view-compact {
    border-radius: 5px;
    cursor: pointer;
    /* transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 5;
}

.compact-view {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #333;
    border-radius: 10px;
    width: 80vw;
    justify-content: space-between;
    margin-top: 1.8em;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 10px 10px rgba(0, 0, 0, 0.1);
}
  
.compact-view:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.compact-score {
    width: 18%;
}

.compact-view-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1em;
    margin-left: 1.5em;
    gap: 1em;
    /* width: 20%; */
}

.compact-view-summary {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    max-width: 60%;
    margin-top: -1.3em;
    color: #ddd;
    font-size: 1.3rem;
    font-weight: medium;
}
  
.compact-view h3 {
    margin: 0;
    font-size: 2.3rem;
    font-weight: bold;
    color: #ddd;
}
  
.compact-view .data-counts {
    display: flex;
    gap: .5em;
    color: #ddd;
}

.compact-view .data-counts-row {
    gap: 1em;
    color: #ddd;
    background-color: #2a2a2a;
    padding: .6em .7em;
    border-radius: 5px;
}
  
.compact-view .data-counts p {
    margin-top: 5px;
    margin-bottom: 0;
    font-size: 1.1rem;
    font-weight: bold;
}
  
.compact-view .keywords {
    color: #ddd;
    opacity: 0.8;
    display: flex;
    align-items: flex-start;
    text-align: left;
    height: 100%;
}
  
.compact-view .keywords span {
    padding: 2px 4px;
    border-radius: 3px;
    font-size: 1.2rem;
    text-transform: capitalize;
}

@media (max-width: 768px) {
    .compact-view {
        width: 90vw;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .compact-view-header {
        flex-direction: row;
        width: 96%;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.5em;
        margin-left: 0.5em;

    }

    .compact-view h3 {
        font-size: 1.8rem;
    }

    .compact-view-summary {
        margin-left: .5em;
        font-size: 1.1rem;
        max-width: 97%;
    }

    .compact-view .keywords {
        font-size: 1.1rem;
        flex-wrap: wrap;
        width: 100%;
    }

    .compact-view .data-counts {
        margin-left: .5em;
        font-size: 1.1rem;
    }

    .compact-score {
        align-self: center;
        width: 75%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}